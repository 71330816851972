import React from "react";
import { getColors } from "../../../utils";
import { Container, ContainerFW } from "../../../header";
import { FeaturesList } from "./FeaturesList/FeaturesList";
import { TrustPilotMiniBanner } from "./TrustPilotMiniBanner/TrustPilotMiniBanner";
import { CheckOutButton } from "./CheckOutButton/CheckOutButton";
export const StatsLineRibbonBanner = ({ features, backgroundColor, trustPilotLocale, checkOutButtonValues, trustPilotTheme = "light" }) => {
    const { bgColor } = getColors(backgroundColor);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor, className: "g-container--new" },
        React.createElement(Container, { className: "g-container--new" },
            React.createElement("div", { className: "stats-line-ribbon-banner__root" },
                React.createElement("div", { className: "stats-line-ribbon-banner__root__features" }, features.length > 0 && features.map((a) => (React.createElement(FeaturesList, { key: `unique${a.featureTitle}`, ...a })))),
                React.createElement(TrustPilotMiniBanner, { theme: trustPilotTheme, locale: trustPilotLocale }),
                React.createElement(CheckOutButton, { ...checkOutButtonValues })))));
};
