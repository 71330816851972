import React, { useState, useEffect } from "react";
import { DotLottiePlayer } from "@dotlottie/react-player";
import { ImgV2 } from "../../ImgV2";
export const SmallCardWithAnimatedIcon = ({ title, animation, linkUrl, linkTarget }) => {
    const [dotLottieOrImgV2, setDotLottieOrImgV2] = useState(Boolean);
    useEffect(() => {
        animation.includes(".lottie") ? setDotLottieOrImgV2(true) : setDotLottieOrImgV2(false);
    }, [animation]);
    return (React.createElement("div", { className: "small-card-with-animated-icon" },
        React.createElement("a", { href: linkUrl, target: linkTarget === false ? "_self" : "_blank", className: "small-card-with-animated-icon__container", rel: "noreferrer" },
            React.createElement("div", { className: "small-card-with-animated-icon__animation-wrapper" }, dotLottieOrImgV2 ? React.createElement(DotLottiePlayer, { src: animation, autoplay: false, loop: true, hover: true }) : React.createElement(ImgV2, { src: animation, alt: title })),
            React.createElement("div", { className: "small-card-with-animated-icon__title-wrapper" }, title))));
};
