import React, { useState, useEffect } from "react";
import { Container, ContainerFW } from "../../global";
import { Img } from "../../Img";
import { usePageViewStyle } from "../../../hooks";
import { ButtonV2 } from "../../../Components/ButtonV2";
import { IconChevronRight } from "../../../Components/svgIcons/chevronRight";
export const HeroBannerWithImagesHomeUplift = ({ title, subtitle, desktopImage, mobileImage, buttonLabel, buttonHref, buttonHrefMobile, buttonOpenInNewTab, linkHref, linkLabel, backgroundColor, actionMessageLink, actionMessageBadge, actionMessageText, actionMessageLinkInNewTab }) => {
    const [buttonUrl, setButtonUrl] = useState(buttonHref);
    const { isLarge } = usePageViewStyle();
    const [imageEl, setImageEl] = useState(React.createElement(React.Fragment, null));
    useEffect(() => {
        setImageEl(isLarge ? React.createElement(Img, { src: desktopImage, alt: title, loading: "eager" }) : React.createElement(Img, { src: mobileImage, alt: title, loading: "eager" }));
    }, [isLarge, desktopImage, mobileImage, title]);
    useEffect(() => {
        setButtonUrl((!isLarge && buttonHrefMobile) ? buttonHrefMobile : buttonHref);
    }, [isLarge, buttonHrefMobile, buttonHref]);
    const mainClassName = `${backgroundColor === "White" ? "HeroBannerHomepageUplift white-background" : "HeroBannerHomepageUplift"}`;
    const showActionMessage = () => (actionMessageText && actionMessageLink) &&
        React.createElement("a", { href: actionMessageLink, target: actionMessageLinkInNewTab ? "__blank" : "__self", className: "HeroBannerHomepageUplift__action-message" },
            actionMessageBadge && React.createElement("span", { className: "badge" }, actionMessageBadge),
            actionMessageText,
            IconChevronRight);
    return (React.createElement("div", { className: mainClassName },
        React.createElement(ContainerFW, { className: "g-container--new" },
            React.createElement("div", { className: "streaks-container" },
                React.createElement("div", { className: "streaks" },
                    React.createElement("div", { className: "streak1" }),
                    React.createElement("div", { className: "streak2" }),
                    React.createElement("div", { className: "streak3" }))),
            React.createElement("div", { className: "HeroBannerHomepageUplift__container" },
                React.createElement(Container, { className: "g-container--new" },
                    React.createElement("div", { className: "HeroBannerHomepageUplift__content" },
                        showActionMessage(),
                        React.createElement("h1", { className: "h1-v2dot6 titleWrapper__title", dangerouslySetInnerHTML: {
                                __html: title,
                            } }),
                        React.createElement("div", { className: "titleWrapper__subtitle", dangerouslySetInnerHTML: {
                                __html: subtitle,
                            } }),
                        React.createElement("div", { className: "buttonsWrapper" },
                            React.createElement(ButtonV2, { classes: "tmx25-round-green-lightgreen", style: { borderRadius: "38px" }, href: buttonUrl, openInNewTab: buttonOpenInNewTab }, buttonLabel),
                            React.createElement(React.Fragment, null, (linkLabel && linkHref) ? React.createElement(ButtonV2, { design: "ghost-large-wbg", classes: "login-button", href: linkHref }, linkLabel) : ""))),
                    React.createElement("div", { className: "HeroBannerHomepageUplift__image" }, imageEl))))));
};
