import React, { useCallback } from "react";
export const LiveChatCTA = ({ liveChatText }) => {
    const simulateMouseClick = useCallback((element) => {
        const mouseClickEvents = ["mousedown", "click", "mouseup"];
        mouseClickEvents.forEach(mouseEventType => element.dispatchEvent(new MouseEvent(mouseEventType, {
            view: window,
            bubbles: true,
            cancelable: true,
            buttons: 1
        })));
    }, []);
    const simulateMouseClickOnZendeskChatIcon = useCallback((event) => {
        const el = event.target.closest("A");
        if (el && event.currentTarget.contains(el)) {
            const element = document.querySelector("a[class=\"chat-toggle\"]");
            simulateMouseClick(element);
        }
    }, [simulateMouseClick]);
    return React.createElement("div", null,
        React.createElement("div", { className: "live-chat-cta live-chat-cta--mobile" },
            React.createElement("div", { className: "live-chat-cta__text", dangerouslySetInnerHTML: {
                    __html: liveChatText ?? "",
                } })),
        React.createElement("div", { className: "live-chat-cta--non-mobile" },
            React.createElement("div", { className: "live-chat-cta__text", onClick: simulateMouseClickOnZendeskChatIcon, dangerouslySetInnerHTML: {
                    __html: liveChatText ?? "",
                } })));
};
