import React from "react";
import { Container, ContainerFW } from "../../global";
import { ButtonV2 } from "../../ButtonV2";
import { getColors, getContainerClassPerMaxWidth, } from "../../../utils";
export const FooterBannerV2_6 = ({ title, subtitle, topImageUrl, leftImageUrl, rightImageUrl, buttonHref, buttonText, backgroundColor, containerMaxWidth }) => {
    const { bgColor, textColor } = getColors(backgroundColor);
    const containerMaxWidthClass = containerMaxWidth && getContainerClassPerMaxWidth(containerMaxWidth);
    const containerExtraClass = containerMaxWidthClass
        ? ` ${containerMaxWidthClass}`
        : "";
    return (React.createElement(ContainerFW, { backgroundColor: bgColor, className: containerExtraClass },
        React.createElement(Container, { className: containerExtraClass },
            React.createElement("div", { className: "footer-banner-v2dot6", style: { "--text-color": `${textColor}` } },
                React.createElement("img", { className: "footer-banner-v2dot6__top-image", src: topImageUrl }),
                React.createElement("img", { className: "footer-banner-v2dot6__left-image", src: leftImageUrl }),
                React.createElement("div", { className: "footer-banner-v2dot6__text-and-button" },
                    React.createElement("h3", { className: "footer-banner-v2dot6__title" }, title),
                    React.createElement("div", { className: "footer-banner-v2dot6__subtitle" }, subtitle),
                    React.createElement(ButtonV2, { href: buttonHref, design: "tmx25-round-green-lightgreen" }, buttonText)),
                React.createElement("img", { className: "footer-banner-v2dot6__right-image", src: rightImageUrl })))));
};
