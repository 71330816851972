import * as React from "react";
import { ContainerFW, Container } from "../global";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import { SmallAwardCard } from "../Cards/SmallAwardCardsDeck/SmallAwardCard/SmallAwardCard";
import { getContainerClassPerMaxWidth } from "../../utils";
export const AwardsSlider = (props) => {
    const slidesQuantity = props.awards.length > 4 ? 4 : props.awards.length;
    const containerMaxWidthClass = props.containerMaxWidth && getContainerClassPerMaxWidth(props.containerMaxWidth);
    const containerExtraClass = containerMaxWidthClass ? ` ${containerMaxWidthClass}` : "";
    return (React.createElement(ContainerFW, { backgroundColor: props.backgroundColor, className: containerExtraClass },
        React.createElement("div", { className: "awards" },
            props.title && React.createElement("h2", { className: "h2-v2dot6 awards__title" }, props.title),
            React.createElement(Container, { className: containerExtraClass },
                React.createElement("div", { className: "awards__wrapper" },
                    React.createElement(Swiper, { modules: [Navigation, Pagination], navigation: {
                            nextEl: ".swiper-button-next",
                            prevEl: ".swiper-button-prev",
                        }, breakpoints: {
                            375: {
                                slidesPerView: 1.3,
                            },
                            601: {
                                slidesPerView: 2.2,
                            },
                            1024: {
                                slidesPerView: slidesQuantity
                            },
                        }, pagination: {
                            el: ".awards__pagination",
                            clickable: true,
                            renderBullet: () => "<span class='awards__bullet swiper-pagination-bullet'></span>",
                        } }, props.awards.map((award) => (React.createElement(SwiperSlide, { key: `awards-slide-${award.title}` },
                        React.createElement(SmallAwardCard, { ...award })))))),
                React.createElement("div", { className: "awards__pagination" })))));
};
