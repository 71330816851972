const MEDIA_EXTENSIONS = {
    video: ["mp4", "webm", "avi"],
    image: ["png", "webp", "jpg", "svg"]
};
const getMediaFileExtension = (fileUrl) => fileUrl.includes(".") ? fileUrl.split(".").pop() : undefined;
export const getMediaType = (fileUrl) => {
    const extension = getMediaFileExtension(fileUrl);
    if (!extension) {
        return undefined;
    }
    return Object.entries(MEDIA_EXTENSIONS).find(([, extensions]) => extensions.includes(extension?.toLowerCase()))?.[0];
};
