import React, { useState } from "react";
import { useComponentIntersection } from "./hooks/useComponentIntersection";
import { ThreeColsSectionMediaAndButtonsBase } from "./ThreeColsSectionMediaAndButtonsBase";
const INTERSECTION_THRESHOLDS = [
    0.3,
    0.4,
    0.45
];
export const ThreeColsSectionMediaAndButtonsIntersectionObserver = ({ title, cardsGroup1, cardsGroup2, mediaProps, mobileAppDownloadProps, ctaButtons, backgroundImageUrl, backgroundColor, animationType = "none", anchorId = "" }) => {
    const [interSectionPhase, setIntersectionPhase] = useState(0);
    const { ref } = useComponentIntersection(INTERSECTION_THRESHOLDS, setIntersectionPhase);
    const getExtraClasses = () => {
        let animationBaseClass = `three-cols-section-media-buttons--animation three-cols-section-media-buttons--animation--${animationType}`;
        if (interSectionPhase) {
            animationBaseClass += " three-cols-section-media-buttons--animation--triggered";
            for (let i = 1; i <= interSectionPhase; i++) {
                animationBaseClass += ` three-cols-section-media-buttons--animation--triggered--${i}`;
            }
            return animationBaseClass;
        }
        return animationBaseClass;
    };
    return (React.createElement(ThreeColsSectionMediaAndButtonsBase, { title: title, cardsGroup1: cardsGroup1, cardsGroup2: cardsGroup2, mediaProps: mediaProps, mobileAppDownloadProps: mobileAppDownloadProps, ctaButtons: ctaButtons, backgroundImageUrl: backgroundImageUrl, backgroundColor: backgroundColor, className: getExtraClasses(), anchorId: anchorId, ref: ref }));
};
