import * as React from "react";
import { getColors } from "../../../../utils/getColors";
import { Heading } from "../../../Heading";
import { ImgV2 } from "../../../ImgV2";
import { DotLottiePlayer } from "@dotlottie/react-player";
export const FeatureCard = ({ cardBackgroundColor, cardTitle, cardDescription, cardClass, cardVideoURL, thumbnailImageURL, cardButtonURL, cardButtonLabel, openOnNewPage, cardLottieURL = "", cardImageURL, cardImageAlt }) => {
    const { bgColor } = getColors(cardBackgroundColor);
    const linkTarget = openOnNewPage === true ? "_blank" : "_self";
    const [dotLottieOrImgV2, setDotLottieOrImgV2] = React.useState(Boolean);
    const [videoPath, setVideoPath] = React.useState(cardVideoURL);
    React.useEffect(() => {
        cardLottieURL.includes(".lottie") ? setDotLottieOrImgV2(true) : setDotLottieOrImgV2(false);
    }, [cardLottieURL]);
    React.useEffect(() => {
        setVideoPath(cardVideoURL);
    }, [cardVideoURL]);
    const cardType = () => {
        if (cardClass === "item0") {
            return (React.createElement("div", { style: { backgroundColor: bgColor }, className: cardClass },
                React.createElement("video", { autoPlay: true, loop: true, muted: true, playsInline: true, poster: thumbnailImageURL },
                    React.createElement("source", { src: videoPath, type: "video/mp4" })),
                React.createElement("div", { className: "item0-text-wrapper" },
                    React.createElement(Heading, { design: "h3-v2dot6" }, cardTitle),
                    React.createElement("p", { dangerouslySetInnerHTML: {
                            __html: cardDescription,
                        } })),
                React.createElement("a", { href: cardButtonURL, target: linkTarget, rel: "noreferrer" }, cardButtonLabel)));
        }
        else if (cardClass === "item1") {
            return (React.createElement("div", { style: { backgroundColor: bgColor }, className: cardClass },
                React.createElement("div", { className: "item1-text-wrapper" },
                    React.createElement(Heading, { design: "h4-v2dot6" }, cardTitle),
                    React.createElement("p", { dangerouslySetInnerHTML: {
                            __html: cardDescription,
                        } }),
                    React.createElement("a", { href: cardButtonURL, target: linkTarget, className: "item1-button", rel: "noreferrer" }, cardButtonLabel)),
                React.createElement(ImgV2, { src: cardImageURL, alt: cardImageAlt })));
        }
        else if (cardClass === "item2") {
            return (React.createElement("div", { style: { backgroundColor: bgColor }, className: cardClass },
                React.createElement("div", { className: "item2-text-wrapper" },
                    React.createElement(Heading, { design: "h4-v2dot6" }, cardTitle),
                    React.createElement("p", { dangerouslySetInnerHTML: {
                            __html: cardDescription,
                        } }),
                    React.createElement("a", { href: cardButtonURL, target: linkTarget, className: "item2-button", rel: "noreferrer" }, cardButtonLabel)),
                dotLottieOrImgV2 ? React.createElement(DotLottiePlayer, { src: cardLottieURL, autoplay: true, loop: true }) : React.createElement("div", { className: "item2-image-container" },
                    React.createElement(ImgV2, { src: cardLottieURL, alt: cardImageAlt }))));
        }
        else {
            return (React.createElement(React.Fragment, null));
        }
    };
    return (cardType());
};
