import React from "react";
const getClassName = (design, className) => `${design ? `${design}` : ""}${className ? ` ${className}` : ""}`;
export const Heading = ({ design, style, children, className }) => {
    const headingClassName = getClassName(design, className);
    if (design?.includes("h6")) {
        return (React.createElement(React.Fragment, null,
            React.createElement("h6", { className: headingClassName, style: style },
                " ",
                children)));
    }
    if (design?.includes("h5")) {
        return (React.createElement(React.Fragment, null,
            React.createElement("h5", { className: headingClassName, style: style },
                " ",
                children)));
    }
    if (design?.includes("h4")) {
        return (React.createElement(React.Fragment, null,
            React.createElement("h4", { className: headingClassName, style: style },
                " ",
                children)));
    }
    if (design?.includes("h3")) {
        return (React.createElement(React.Fragment, null,
            React.createElement("h3", { className: headingClassName, style: style },
                " ",
                children)));
    }
    if (design?.includes("h1")) {
        return (React.createElement(React.Fragment, null,
            React.createElement("h1", { className: headingClassName, style: style },
                " ",
                children)));
    }
    if (design?.includes("jumbo")) {
        return (React.createElement(React.Fragment, null,
            React.createElement("h1", { className: headingClassName, style: style },
                " ",
                children)));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("h2", { className: headingClassName, style: style },
            " ",
            children)));
};
