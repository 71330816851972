import React, { forwardRef, useCallback, useState, } from "react";
import { ContainerFW, Container } from "../global";
import { SmartphoneFrameWithMedia, } from "./SmartphoneFrameWithMedia";
import { LinkPillsPair } from "./LinkPillsPair";
import { PortalledMobileAppDownload, } from "./MobileAppDownload/MobileAppDownload";
import { SimpleIconAndTextCardDeck, } from "./SimpleIconAndTextCardDeck";
export const ThreeColsSectionMediaAndButtonsBase = forwardRef(({ title, cardsGroup1, cardsGroup2, mediaProps, mobileAppDownloadProps, ctaButtons, backgroundImageUrl, backgroundColor, className, anchorId = "" }, ref) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleMobileDownloadButton = useCallback((event) => {
        event.preventDefault();
        setIsModalOpen(!isModalOpen);
    }, [isModalOpen]);
    const getEnhancedCtaButtons = () => {
        if (ctaButtons) {
            return ctaButtons.reduce((newCtaButtons, buttonProps) => {
                if (buttonProps.purpose === "mobile-app-button") {
                    const newButtonProps = {
                        ...buttonProps,
                        onClick: handleMobileDownloadButton,
                    };
                    newCtaButtons.push(newButtonProps);
                    return newCtaButtons;
                }
                newCtaButtons.push(buttonProps);
                return newCtaButtons;
            }, []);
        }
        return ctaButtons;
    };
    return (React.createElement(ContainerFW, { className: "g-container--new", backgroundColor: backgroundColor },
        React.createElement("div", { className: "three-cols-section-media-buttons__container", style: {
                "--background-image-url": `url(${backgroundImageUrl})`,
            }, id: anchorId },
            React.createElement(Container, { className: "g-container--new" },
                React.createElement("div", { className: `three-cols-section-media-buttons ${className ? className : ""}`, ref: ref },
                    React.createElement("h2", { className: "h2-v2dot6 three-cols-section-media-buttons__title" }, title),
                    React.createElement("div", { className: "three-cols-section-media-buttons__media-container" },
                        React.createElement(SmartphoneFrameWithMedia, { frameImageUrl: mediaProps.frameImageUrl, mediaFileUrl: mediaProps.mediaFileUrl, posterUrl: mediaProps.posterUrl })),
                    React.createElement(SimpleIconAndTextCardDeck, { cards: cardsGroup1.cards, className: "three-cols-section-media-buttons__usps-1" }),
                    React.createElement(SimpleIconAndTextCardDeck, { cards: cardsGroup2.cards, className: "three-cols-section-media-buttons__usps-2" }),
                    Array.isArray(ctaButtons) &&
                        ctaButtons.length > 0 && (React.createElement("div", { className: "three-cols-section-media-buttons__cta" },
                        React.createElement(LinkPillsPair, { pills: getEnhancedCtaButtons() })))),
                mobileAppDownloadProps && isModalOpen && (React.createElement(PortalledMobileAppDownload, { title: mobileAppDownloadProps.title, subtitle: mobileAppDownloadProps.subtitle, qRCodeImageUrl: mobileAppDownloadProps.qRCodeImageUrl, downloadLinks: mobileAppDownloadProps.downloadLinks, onClose: handleMobileDownloadButton }))))));
});
ThreeColsSectionMediaAndButtonsBase.displayName =
    "ThreeColsSectionMediaAndButtonsBase";
