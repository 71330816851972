import { useRef } from "react";
import { useInView } from "react-intersection-observer";
export const useComponentIntersection = (intersectionThresholds, phaseSettingFunction) => {
    const isIntersectionCompleteRef = useRef(false);
    const passedThresholdsRef = useRef([]);
    const manageIntersections = (entry, phaseSettingFunction) => {
        const sorted_thresholds = [...intersectionThresholds].sort((a, b) => b - a);
        for (let i = 0; i < sorted_thresholds.length; i++) {
            if (entry.intersectionRatio >= sorted_thresholds[i]) {
                if (passedThresholdsRef.current.includes(sorted_thresholds[i])) {
                    continue;
                }
                const phaseNum = sorted_thresholds.length - i;
                passedThresholdsRef.current.push(sorted_thresholds[i]);
                phaseSettingFunction(phaseNum);
                if (i === 0) {
                    isIntersectionCompleteRef.current = true;
                }
                return;
            }
        }
    };
    const handleIntersectionChange = (inView, entry) => {
        if (isIntersectionCompleteRef.current) {
            return;
        }
        manageIntersections(entry, phaseSettingFunction);
    };
    const { ref } = useInView({
        threshold: intersectionThresholds,
        onChange: handleIntersectionChange
    });
    return {
        ref
    };
};
