import React from "react";
import { getMediaType } from "./utils";
export const MediaViewer = ({ mediaFileUrl, posterUrl, isVideoLooping = true }) => {
    const mediaType = getMediaType(mediaFileUrl);
    if (mediaType === "image") {
        return (React.createElement("div", { className: "media-viewer" },
            React.createElement("img", { src: mediaFileUrl })));
    }
    if (mediaType === "video") {
        return (React.createElement("div", { className: "media-viewer" },
            React.createElement("video", { src: mediaFileUrl, poster: posterUrl, autoPlay: true, muted: true, playsInline: true, loop: isVideoLooping })));
    }
    return React.createElement(React.Fragment, null);
};
