import React from "react";
import { Container, ContainerFW } from "../global";
import { CardWithIconTextsLink, } from "./CardWithIconTextsLink/CardWithIconTextsLink";
import { ButtonV2 } from "../ButtonV2";
export const M30CardsWithIconTextsLink = ({ title, subTitle, cards, backgroundColor, buttonUrl, buttonOpenInNewTab, buttonDesign, buttonLabel, }) => (React.createElement(ContainerFW, { className: "g-container--new", backgroundColor: backgroundColor },
    React.createElement(Container, { className: "g-container--new" },
        React.createElement("div", { className: "m30-cards-with-icon-texts-link" },
            title && React.createElement("h2", { className: "h2-v2_6" }, title),
            subTitle && React.createElement("p", null, subTitle),
            React.createElement("div", { className: "m30-cards-with-icon-texts-link__cards" }, Array.isArray(cards) &&
                cards.map((card, index) => (React.createElement(CardWithIconTextsLink, { key: index, iconUrl: card.iconUrl, iconAlt: card.iconAlt, title: card.title, subTitle: card.subTitle, cardBackground: card.cardBackground, contentTextColor: card.contentTextColor, linkText: card.linkText, linkUrl: card.linkUrl, linkOpensInNewTab: card.linkOpensInNewTab })))),
            buttonLabel && (React.createElement(ButtonV2, { href: buttonUrl, openInNewTab: buttonOpenInNewTab, design: buttonDesign, showArrowRight: true }, buttonLabel))))));
