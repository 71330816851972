import React from "react";
import { Logo } from "../../Logo";
import { SocialMedia } from "./SocialMedia";
import { LiveChatCTA } from "./LiveChatCTA";
export const SocialBarLogoLiveChat = ({ logo, socialMedia, liveChatCTA, }) => (React.createElement("div", { className: "social-bar-logo-livechat" },
    React.createElement("div", { className: "social-bar-logo-livechat__logo" },
        React.createElement(Logo, { imageUrl: logo.logoImageUrl, alt: logo.logoImageAlt, logoHref: logo.logoHref })),
    liveChatCTA?.liveChatText && (React.createElement("div", { className: "social-bar-logo-livechat__livechat-cta" },
        React.createElement(LiveChatCTA, { liveChatText: liveChatCTA?.liveChatText }))),
    React.createElement(SocialMedia, { socialMedia: socialMedia })));
