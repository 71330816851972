import React, { useEffect, useState } from "react";
import { Heading } from "../../Heading";
import { ButtonV2 } from "../../ButtonV2";
import { usePageViewStyle } from "../../../hooks";
import { handleLinkTarget } from "../../../utils";
export const MarketCard = ({ categoryName, linkLabel, linkUrl, subtitle, title, mobileBgImage, desktopBgImage, openInNewTabButton = false }) => {
    const { isSmall } = usePageViewStyle();
    const [linkCard, setLinkCard] = useState(false);
    const hrefTarget = handleLinkTarget(openInNewTabButton);
    useEffect(() => {
        setLinkCard(isSmall);
    }, [isSmall]);
    return (linkCard ? React.createElement("a", { href: linkUrl, target: hrefTarget, className: "market-card-root", style: { "--background-image-url-mobile": `url(${mobileBgImage})`, "--background-image-url-desktop": `url(${desktopBgImage})` } },
        React.createElement("p", { className: "caption-risk-warning-bold" }, categoryName),
        React.createElement("div", { className: "market-card-root__title-wrapper" },
            React.createElement(Heading, { design: "h4-v2dot6" }, title),
            React.createElement("p", { className: "market-card-root__title-wrapper__subtitle" }, subtitle)))
        : React.createElement("div", { className: "market-card-root", style: { "--background-image-url-mobile": `url(${mobileBgImage})`, "--background-image-url-desktop": `url(${desktopBgImage})` } },
            React.createElement("p", { className: "caption-risk-warning-bold" }, categoryName),
            React.createElement("div", { className: "market-card-root__title-wrapper" },
                React.createElement(Heading, { design: "h4-v2dot6" }, title),
                React.createElement("p", { className: "market-card-root__title-wrapper__subtitle" }, subtitle)),
            React.createElement(ButtonV2, { classes: "market-card-root__button", openInNewTab: openInNewTabButton, href: linkUrl }, linkLabel)));
};
