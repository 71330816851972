import React from "react";
import { Container, ContainerFW } from "../global";
import { FooterBannerV2_6 } from "./FooterBannerV2_6";
import { SocialBarLogoLiveChat } from "./SocialBarWithLogoAndLiveChat";
import { PaymentMethodsIcons } from "./PaymentMethodsIcons";
import { FooterNavV2_6 } from "./FooterNavV2_6";
export const FooterV2_6 = ({ socialBarLogoLiveChat, paymentMethodsIcons, footerNavigationProps, legalInfo, backgroundColor, hasBanner = true, footerBannerProps, }) => (React.createElement(ContainerFW, { backgroundColor: backgroundColor, className: "g-container--new" },
    React.createElement("div", { className: "footer-v2dot6" },
        hasBanner && footerBannerProps && (React.createElement(FooterBannerV2_6, { title: footerBannerProps.title, subtitle: footerBannerProps.subtitle, topImageUrl: footerBannerProps.topImageUrl, leftImageUrl: footerBannerProps.leftImageUrl, rightImageUrl: footerBannerProps.rightImageUrl, buttonHref: footerBannerProps.buttonHref, buttonText: footerBannerProps.buttonText, backgroundColor: footerBannerProps.backgroundColor, containerMaxWidth: footerBannerProps.containerMaxWidth })),
        React.createElement(Container, { className: "g-container--new" },
            React.createElement(SocialBarLogoLiveChat, { logo: socialBarLogoLiveChat.logo, socialMedia: socialBarLogoLiveChat.socialMedia, liveChatCTA: socialBarLogoLiveChat.liveChatCTA })),
        React.createElement(Container, { className: "g-container--new" }, Array.isArray(paymentMethodsIcons.icons) && React.createElement(PaymentMethodsIcons, { title: paymentMethodsIcons.title, icons: paymentMethodsIcons.icons, note: paymentMethodsIcons.note })),
        React.createElement(Container, { className: "g-container--new" },
            React.createElement(FooterNavV2_6, { navSections: footerNavigationProps.navSections })),
        React.createElement(Container, { className: "g-container--new" },
            React.createElement("div", { className: "footer-v2dot6__legal-info", dangerouslySetInnerHTML: { __html: legalInfo } })))));
