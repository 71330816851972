import React from "react";
import { ContainerFW, Container } from "../global";
import { Heading } from "../Heading";
import { ButtonV2 } from "../ButtonV2/ButtonV2";
import { getColors } from "../../utils";
const getHeadingDesignStyle = (headingStyle) => {
    const headingType = headingStyle?.split("-")[0].toString();
    return headingType ? `${headingType}-v2dot6` : undefined;
};
export const TitleSubTitleCTA = ({ title, subTitle, buttonLabel, buttonOnLargeOnly, buttonURL, backgroundColor, centered, headingStyle, buttonOnLargeAndMediumOnly, buttonOpensInNewTab }) => {
    const { bgColor } = getColors(backgroundColor);
    return (React.createElement(ContainerFW, { className: "g-container--new", backgroundColor: bgColor },
        React.createElement(Container, { className: "g-container--new" },
            React.createElement("div", { className: `titleSubTitleCTA ${centered ? "titleSubTitleCTA__centered--tm" : ""} ${buttonOnLargeOnly ? "titleSubTitleCTA__hide-button--tm" : ""}  ${buttonOnLargeAndMediumOnly ? "titleSubTitleCTA__hide-button--mobile" : ""}` },
                React.createElement("div", { className: "titleSubTitleCTA__content" },
                    React.createElement(Heading, { design: getHeadingDesignStyle(headingStyle) }, title),
                    React.createElement("p", { className: "titleSubTitleCTA__text" }, subTitle)),
                (buttonLabel && buttonURL) && React.createElement(ButtonV2, { href: buttonURL, openInNewTab: buttonOpensInNewTab, design: "ghost-large-wbg" }, buttonLabel)))));
};
