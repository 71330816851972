import React from "react";
import { Heading } from "../../Heading";
import { ImgV2 } from "../../ImgV2";
import { LinkWithTargetAndRel } from "../LinkWithTargetAndRel";
export const CardWithIconTextsLink = ({ iconUrl, subTitle, title, cardBackground, contentTextColor, iconAlt, linkUrl, linkText, linkOpensInNewTab = false, }) => (React.createElement("div", { className: "card-with-icon-texts-link", style: {
        "--content-text-color": contentTextColor,
        "--background-color": cardBackground,
    } },
    React.createElement(ImgV2, { src: iconUrl, alt: iconAlt, className: "card-with-icon-texts-link__icon" }),
    subTitle && (React.createElement("div", { className: "card-with-icon-texts-link__subtitle", dangerouslySetInnerHTML: {
            __html: subTitle,
        } })),
    React.createElement(LinkWithTargetAndRel, { className: "card-with-icon-texts-link__title-link", linkUrl: linkUrl, linkOpensInNewTab: linkOpensInNewTab },
        React.createElement(Heading, { design: "h6-v2dot6", className: "card-with-icon-texts-link__title" }, title)),
    linkUrl && linkText && (React.createElement("div", { className: "card-with-icon-texts-link__link" },
        React.createElement(LinkWithTargetAndRel, { linkUrl: linkUrl, linkOpensInNewTab: linkOpensInNewTab }, linkText)))));
