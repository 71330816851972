import React from "react";
import { Container, ContainerFW } from "../global";
import { PeopleCard } from "./PeopleCard/PeopleCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { getColors } from "../../utils";
export const M29OurPeopleImageGallery = ({ cards, title, backgroundColor, }) => {
    const { bgColor } = getColors(backgroundColor);
    return (React.createElement(ContainerFW, { className: "g-container--new", backgroundColor: bgColor },
        React.createElement(Container, { className: "g-container--new our-people-image-gallery" },
            React.createElement("p", { className: "our-people-image-gallery__title" }, title),
            React.createElement(Swiper, { breakpoints: {
                    375: {
                        slidesPerView: 1.1,
                        spaceBetween: 10,
                    },
                    601: {
                        slidesPerView: 2.7,
                        spaceBetween: 10,
                    },
                    1024: {
                        slidesPerView: 3,
                        spaceBetween: 10,
                    },
                } }, Array.isArray(cards) &&
                cards.map((card) => (React.createElement(SwiperSlide, { key: `card-slide-${card.personName}` },
                    React.createElement(PeopleCard, { ...card }))))))));
};
