import * as React from "react";
import { Container, ContainerFW } from "../../global";
import { getColors } from "../../../utils/getColors";
import { FeatureCard } from "./FeatureCard/FeatureCard";
export const FeatureCardsSection = ({ backgroundColor, cards }) => {
    const { bgColor } = getColors(backgroundColor);
    return (React.createElement("div", { className: "feature-cards-section", style: { backgroundColor: bgColor } },
        React.createElement(ContainerFW, null,
            React.createElement(Container, { className: "g-container--new" },
                React.createElement("div", { className: "feature-cards-section__cards-container" }, cards.map((card, index) => React.createElement(FeatureCard, { key: index, ...card, cardClass: `item${index}` })))))));
};
