import React, { useState, useEffect } from "react";
import { Img } from "../Img";
import { Container, ContainerFW } from "../global";
import { usePageViewStyle } from "../../hooks";
import { Heading } from "../Heading";
export const RichTextWithImageUplift = ({ title, richText, imageURL, mobileImageURL, imageAlt, position, textAlignment = "left", titletype, mainContainerWidth1400 = false, descriptionColor = "#0E1D31", headingTextColor = "#18283D", haveItems = false, item1title, item1subtitle, item2title, item2subtitle, item3title, item3subtitle }) => {
    const { isSmall, isLarge, isMedium } = usePageViewStyle();
    const [isSmallValue, setIsSmallValue] = useState(false);
    const [isMediumValue, setIsMediumValue] = useState(false);
    const [isLargeValue, setIsLargeValue] = useState(false);
    useEffect(() => {
        setIsSmallValue(isSmall);
        setIsMediumValue(isMedium);
        setIsLargeValue(isLarge);
    }, [isSmall, isMedium, isLarge]);
    const headingSection = (titleLabel, titleType, titleTextAlignment, titleTextColor) => (titleLabel !== "" && React.createElement(Heading, { design: titleType ? titleType : "h2-v2dot6", style: { "--text-alignment": `${titleTextAlignment}`, color: titleTextColor } }, titleLabel));
    const descriptionSection = (descriptionText, descriptionTextAlignment, descriptionTextColor) => (richText !== "" && React.createElement("div", { className: "rich-text-with-image-uplift__text", dangerouslySetInnerHTML: {
            __html: descriptionText,
        }, style: { "--text-alignment": `${descriptionTextAlignment}`, color: descriptionTextColor } }));
    const imageSection = (desktopAndTabletImage, phoneImage, desktopAndMobileImageAlt) => (React.createElement("div", { className: "rich-text-with-image-uplift__image-container" },
        (isLargeValue || isMediumValue) && React.createElement(Img, { className: "rich-text-with-image-uplift__image", src: desktopAndTabletImage, alt: desktopAndMobileImageAlt }),
        isSmallValue && React.createElement(Img, { className: "rich-text-with-image-uplift__image", src: phoneImage, alt: desktopAndMobileImageAlt })));
    const itemsSection = (haveSection, firstItemtitle, firstItemsubtitle, secondItemtitle, secondItemsubtitle, thirdItemtitle, thirdItemsubtitle) => (haveSection && React.createElement("div", { className: "rich-text-with-image-uplift__items-wrapper" },
        React.createElement("div", { className: "rich-text-with-image-uplift__item1" },
            React.createElement("p", { className: "rich-text-with-image-uplift__item-title" }, firstItemtitle),
            React.createElement("p", { className: "rich-text-with-image-uplift__item-subtitle" }, firstItemsubtitle)),
        React.createElement("div", { className: "rich-text-with-image-uplift__item2" },
            React.createElement("p", { className: "rich-text-with-image-uplift__item-title" }, secondItemtitle),
            React.createElement("p", { className: "rich-text-with-image-uplift__item-subtitle" }, secondItemsubtitle)),
        React.createElement("div", { className: "rich-text-with-image-uplift__item3" },
            React.createElement("p", { className: "rich-text-with-image-uplift__item-title" }, thirdItemtitle),
            React.createElement("p", { className: "rich-text-with-image-uplift__item-subtitle" }, thirdItemsubtitle))));
    return (React.createElement("div", { className: "rich-text-with-image-uplift-main" },
        React.createElement(ContainerFW, { backgroundColor: "#fff" },
            React.createElement(Container, { className: `${mainContainerWidth1400 ? "g-container--new" : ""}` },
                React.createElement("div", { className: `rich-text-with-image-uplift ${position}` },
                    React.createElement("div", { className: "rich-text-with-image-uplift__text-container" },
                        headingSection(title, titletype, textAlignment, headingTextColor),
                        descriptionSection(richText, textAlignment, descriptionColor),
                        itemsSection(haveItems, item1title, item1subtitle, item2title, item2subtitle, item3title, item3subtitle)),
                    imageSection(imageURL, mobileImageURL, imageAlt))))));
};
