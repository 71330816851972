import React from "react";
import { Container, ContainerFW } from "../global";
import { Swiper, SwiperSlide, } from "swiper/react";
import { SmallCardWithAnimatedIcon } from "./SmallCardWithAnimatedIcon/SmallCardWithAnimatedIcon";
import { getColors } from "../../utils";
export const SmallCardsSlider = (props) => {
    const { bgColor } = getColors(props.backgroundColor || "neutralaccentbackground");
    return (React.createElement("div", { className: "small-cards-slider" },
        React.createElement(ContainerFW, { backgroundColor: bgColor, className: "g-container--new" },
            React.createElement(Container, { className: "g-container--new" },
                React.createElement(Swiper, { spaceBetween: 0, slidesPerView: "auto" }, props.content.map((item, i) => React.createElement(SwiperSlide, { key: `small-cards-slider-item-${i}` },
                    React.createElement(SmallCardWithAnimatedIcon, { title: item.title, animation: item.animation, linkUrl: item.linkUrl, linkTarget: item.linkTarget }))))))));
};
