import React, { useEffect, useMemo, useRef } from "react";
export const TrustPilotMiniBanner = ({ locale = "en", theme = "light" }) => {
    const containerRef = useRef(null);
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
        script.async = true;
        script.type = "text/javascript";
        if (containerRef.current) {
            containerRef.current.appendChild(script);
        }
    }, []);
    const handleTVWidget = useMemo(() => (`<div class="trustpilot-widget" data-theme=${theme} data-font-family="Figtree" data-locale=${locale} data-template-id="5419b637fa0340045cd0c936" data-businessunit-id="597f015c0000ff0005a7cd72" data-style-height="20px" data-style-width="100%">
        <a href="https://uk.trustpilot.com/review/www.thinkmarkets.com" target="_blank" rel="noopener">Trustpilot</a>
        </div>`), [locale, theme]);
    return (React.createElement("div", { ref: containerRef, dangerouslySetInnerHTML: {
            __html: handleTVWidget,
        } }));
};
