import React from "react";
import { ImgV2 } from "../../ImgV2";
import { ContainerFW } from "../../../header";
import { getColors } from "../../../utils";
import { ButtonV2 } from "../../ButtonV2";
import { Heading } from "../../Heading";
export const HeroBannerWithImagesAndAnimation = ({ title, subTitle, buttonLabel, buttonUrl, bgImage, backgroundColor, buttonOpenInNewTab = false }) => {
    const { bgColor } = getColors(backgroundColor);
    return (React.createElement("div", { className: "hero-banner-animation" },
        React.createElement(ContainerFW, { className: "g-container--new", backgroundColor: bgColor },
            React.createElement(Heading, { design: "h1-regular" }, title),
            React.createElement(Heading, { design: "h5-semi-bold" }, subTitle),
            React.createElement(ButtonV2, { showArrowRight: true, design: "cta-medium-wbg", href: buttonUrl, openInNewTab: buttonOpenInNewTab }, buttonLabel),
            React.createElement("div", { className: "hero-banner-animation__bg-image" },
                React.createElement(ImgV2, { src: bgImage })))));
};
