import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import { IconClose } from "../../../Components/svgIcons";
export const MobileAppDownloadPopin = ({ title, subtitle, qRCodeImageUrl, downloadLinks, onClose }) => {
    useEffect(() => {
        const handleEsc = (event) => {
            if (event.key === "Escape") {
                onClose && onClose(event);
            }
        };
        document.addEventListener("keydown", handleEsc);
        return () => {
            document.removeEventListener("keydown", handleEsc);
        };
    }, [onClose]);
    return React.createElement("div", { className: "mobile-app-download-popin" },
        React.createElement("div", { className: "mobile-app-download-popin__inner" },
            React.createElement("button", { className: "mobile-app-download-popin__close-button", onClick: onClose },
                React.createElement(IconClose, null)),
            React.createElement("div", { className: "mobile-app-download-popin__top" },
                React.createElement("h3", { className: "h3-v2dot6" }, title),
                subtitle && (React.createElement("div", { className: "mobile-app-download-popin__subtitle" }, subtitle))),
            React.createElement("div", { className: "mobile-app-download-popin__QRCode" },
                React.createElement("img", { src: qRCodeImageUrl })),
            Array.isArray(downloadLinks) && downloadLinks.length > 0 && (React.createElement("div", { className: "mobile-app-download-popin__download-links" }, downloadLinks.map(({ href, imageUrl }, index) => (React.createElement("a", { key: index, href: href },
                React.createElement("img", { src: imageUrl }))))))));
};
export const PortalledMobileAppDownload = ({ title, subtitle, qRCodeImageUrl, downloadLinks, onClose }) => createPortal(React.createElement(MobileAppDownloadPopin, { title: title, subtitle: subtitle, downloadLinks: downloadLinks, qRCodeImageUrl: qRCodeImageUrl, onClose: onClose }), document.body);
