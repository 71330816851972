import React from "react";
import { ContainerFW, Container } from "../global";
import { getContainerClassPerMaxWidth, } from "../../utils";
import { getColors } from "../../utils";
export const SingleAwardBanner = ({ title, subTitle, year, posterImgUrl, posterBkgrImgUrl, backgroundColor, containerMaxWidth, }) => {
    const { bgColor } = getColors(backgroundColor);
    const containerMaxWidthClass = containerMaxWidth && getContainerClassPerMaxWidth(containerMaxWidth);
    const containerExtraClass = containerMaxWidthClass
        ? ` ${containerMaxWidthClass}`
        : "";
    const imageUrlCss = posterBkgrImgUrl ? `url(${posterBkgrImgUrl})` : "none";
    return (React.createElement(ContainerFW, { backgroundColor: bgColor, className: containerExtraClass },
        React.createElement(Container, { className: containerExtraClass },
            React.createElement("section", { className: "single-award-banner", style: {
                    "--background-image-url": imageUrlCss,
                } },
                React.createElement("header", { className: "single-award-banner__text-content" },
                    React.createElement("h3", { className: "single-award-banner__title" }, title),
                    React.createElement("div", { className: "single-award-banner__subtitle", dangerouslySetInnerHTML: {
                            __html: subTitle || "",
                        } }),
                    React.createElement("div", { className: "single-award-banner__year" }, year)),
                React.createElement("div", { className: "single-award-banner__poster" },
                    React.createElement("img", { src: posterImgUrl, alt: title }))))));
};
