import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { License } from "./License/License";
import { ContainerFW } from "../global";
import { Autoplay } from "swiper";
export const LicenseSlider = ({ licenses, title, backgroundColor = "#FFF" }) => {
    const [isClient, setIsClient] = useState(false);
    useEffect(() => {
        setIsClient(true);
    }, []);
    return (React.createElement(ContainerFW, { backgroundColor: backgroundColor, className: "g-container--new license-slider-root__container" },
        React.createElement("div", { className: "license-slider-root__container" }, isClient && (React.createElement("div", { className: "license-slider-root", style: { "--gradient-start-color": `${backgroundColor}` } },
            React.createElement("p", { className: "text-nav" }, title),
            React.createElement("div", { className: "license-slider-root__license-wrapper" }, licenses.length > 0 &&
                React.createElement(Swiper, { breakpoints: {
                        375: {
                            spaceBetween: 8,
                        },
                        601: {
                            spaceBetween: 32,
                        },
                        1024: {
                            spaceBetween: 32,
                        },
                        1600: {
                            spaceBetween: 32,
                        },
                    }, slidesPerView: "auto", loop: true, autoplay: {
                        delay: 1,
                        disableOnInteraction: true,
                    }, speed: 4000, allowTouchMove: false, modules: [Autoplay], freeMode: true }, [...licenses, ...licenses, ...licenses].map((license, i) => (React.createElement(SwiperSlide, { key: `license-slide-${i}-${license.title}` },
                    React.createElement(License, { ...license })))))))))));
};
