import React, { useEffect, useState, useCallback, useRef } from "react";
import pako from "pako";
import { Img } from "../Img";
import { InstrumentSliderNew } from "../InstrumentSliderNew";
import { ButtonV2 } from "../ButtonV2";
import { CaretUp, CaretDown } from "../svgIcons";
export const PriceFeedNewSocketComponent = ({ tableInfo, instrumentIcons, marketsInfo, footerText, session, wsUrl, activeMarket, buttonLinkText, buttonLinkUrl, instrumentItems, popularInstrumentToRemove, popularInstrumentToAdd, instrumentsData, culture, hasButtons = false, buttonOpenInNewTab = false }) => {
    const [instruments, setInstruments] = useState([]);
    const [filteredInstruments, setFilteredInstruments] = useState([]);
    const [currentMarketTab, setCurrentMarketTab] = useState(activeMarket);
    const prevAskPrices = useRef(new Map());
    const prevBidPrices = useRef(new Map());
    function getValueByKey(data, key) {
        for (const category of data) {
            if (category[key]) {
                return category[key];
            }
        }
        return [];
    }
    const Forex = getValueByKey(instrumentsData, "forex");
    const Indices = getValueByKey(instrumentsData, "indices");
    const Commodities = getValueByKey(instrumentsData, "commodities");
    const ETFs = getValueByKey(instrumentsData, "etfs");
    const Futures = getValueByKey(instrumentsData, "futures");
    const Stocks = getValueByKey(instrumentsData, "stocks");
    const Crypto = getValueByKey(instrumentsData, "crypto");
    const Popular = getValueByKey(instrumentsData, "popular")?.map(item => item === popularInstrumentToRemove ? popularInstrumentToAdd : item);
    const instrumentsMap = {
        "": [],
        forex: Forex,
        indices: Indices,
        commodities: Commodities,
        etfs: ETFs,
        futures: Futures,
        stocks: Stocks,
        crypto: Crypto,
        popular: Popular,
    };
    useEffect(() => {
        let websocket;
        let interval;
        const startWebsocket = () => {
            websocket = new WebSocket(`${wsUrl}/price-feed/${session}`);
            websocket.binaryType = "arraybuffer";
            const pingRequest = new TextEncoder().encode("ping");
            websocket.onopen = () => {
                interval = setInterval(() => {
                    websocket.send(pingRequest);
                }, 1000);
            };
            websocket.onmessage = (event) => {
                const data = event.data;
                const message = pako.inflate(data, { to: "string" });
                if (message) {
                    updatePriceFeedNewSocketComponent(message);
                }
            };
        };
        startWebsocket();
        setTimeout(() => {
            if (!websocket.OPEN) {
                startWebsocket();
            }
        }, 5000);
        return () => {
            clearInterval(interval);
            websocket.close();
        };
    }, [currentMarketTab]);
    useEffect(() => {
        setFilteredInstruments(instruments.filter((item) => item.groupCode === currentMarketTab));
    }, [currentMarketTab, instruments]);
    const updatePriceFeedNewSocketComponent = (message) => {
        const dict = JSON.parse(message);
        const instruments = [];
        for (const key in dict) {
            if (key.includes("quote")) {
                const group = key.split("_")[0];
                const parsedValues = JSON.parse(dict[key]);
                if (instrumentsMap[group.toLowerCase()]?.includes(parsedValues.Code)) {
                    const dailyChange = calcDailyChange(group, parsedValues.Code, parsedValues.Bid, parsedValues.Ask, dict);
                    const prevBid = prevBidPrices.current.get(parsedValues.Code) ?? 0;
                    const prevAsk = prevAskPrices.current.get(parsedValues.Code) ?? 0;
                    const instrument = {
                        code: parsedValues.Code,
                        groupCode: group,
                        bid: parsedValues.Bid,
                        bidStyle: bidAskStyle(parsedValues.Bid, prevBid),
                        bidIcon: bidAskIcon(parsedValues.Bid, prevBid),
                        ask: parsedValues.Ask,
                        askStyle: bidAskStyle(parsedValues.Ask, prevAsk),
                        askIcon: bidAskIcon(parsedValues.Ask, prevAsk),
                        spread: calcSpread(group, parsedValues.Code, parsedValues.Bid, parsedValues.Ask, dict),
                        dailyChange: `${dailyChange}%`,
                        dailyChangeStyle: dailyChangeStyle(+dailyChange),
                        dailyChangeIcon: dailyChangeIcon(+dailyChange),
                    };
                    prevAskPrices.current.set(parsedValues.Code, parsedValues.Ask);
                    prevBidPrices.current.set(parsedValues.Code, parsedValues.Bid);
                    instruments.push(instrument);
                }
            }
        }
        setInstruments(instruments);
        setFilteredInstruments(instruments.filter((item) => item.groupCode === currentMarketTab));
    };
    const calcSpread = (group, code, bid, ask, dict) => {
        const marketItem = JSON.parse(dict[`${group}_marketItem_${code}`]);
        let decimalPrecision = 0;
        let tickSize = 0;
        if (dict[`${group}_marketItemInfo_${code}`]) {
            const marketItemInfo = JSON.parse(dict[`${group}_marketItemInfo_${code}`]);
            decimalPrecision =
                marketItemInfo.DecimalPrecision ?? marketItem.DecimalPrecision;
            tickSize = marketItemInfo.TickSize ?? marketItem.TickSize;
        }
        else {
            decimalPrecision = marketItem.DecimalPrecision;
            tickSize = marketItem.TickSize;
        }
        let decimal = tickSize >= 1.0 ? 0 : decimalPrecision;
        decimal = decimal > 2 ? decimal - 1 : decimal;
        const spread = ((ask - bid) * Math.pow(10, decimal)).toFixed(1);
        return spread;
    };
    const calcDailyChange = (group, code, bid, ask, dict) => {
        const now = new Date();
        const day = now.getUTCDate() - 1;
        if (dict[`${group}_prevDayClosePrice_${code}_${day}`]) {
            const parsedValues = JSON.parse(dict[`${group}_prevDayClosePrice_${code}_${day}`]);
            const prevClose = parsedValues.PrevClose ?? 0;
            const averageCurrentPrice = (ask + bid) / 2;
            const dailyValue = averageCurrentPrice - prevClose;
            const dailyPercent = ((dailyValue / prevClose) * 100).toFixed(2);
            return `${dailyPercent}`;
        }
        return "N/A";
    };
    const bidAskStyle = (currentPrice, previousPrice) => {
        if (currentPrice >= previousPrice) {
            return "price-feed__positive";
        }
        else {
            return "price-feed__negative";
        }
    };
    const dailyChangeStyle = (value) => {
        if (value >= 0) {
            return "price-feed__positive";
        }
        else {
            return "price-feed__negative";
        }
    };
    const bidAskIcon = (currentPrice, previousPrice) => {
        if (currentPrice >= previousPrice) {
            return CaretUp();
        }
        else {
            return CaretDown();
        }
    };
    const dailyChangeIcon = (value) => {
        if (value >= 0) {
            return CaretUp();
        }
        else {
            return CaretDown();
        }
    };
    const handleMarketClick = useCallback((market) => {
        setCurrentMarketTab(market);
    }, []);
    return (React.createElement("div", { className: "price-feed__table" },
        React.createElement(InstrumentSliderNew, { items: marketsInfo, currentMarketTab: activeMarket, onMarketClick: handleMarketClick, renderFlameIcon: true }),
        React.createElement("div", { className: "price-feed__table-data-wrapper" },
            React.createElement("table", { className: "price-feed__table-data" },
                React.createElement("thead", null,
                    React.createElement("tr", { className: "price-feed__header" },
                        tableInfo.map((item, i) => (React.createElement("th", { key: `price-feed__headerCell-${i}`, className: "price-feed__headerCell" }, item.title))),
                        hasButtons && React.createElement("th", { className: "price-feed__headerCell price-feed__buttonCell" })),
                    React.createElement("tr", null,
                        tableInfo.map((_, i) => (React.createElement("td", { key: `price-feed__headerCell-${i}`, className: "price-feed__headerBorder" }))),
                        hasButtons && React.createElement("td", { className: "price-feed__headerBorder price-feed__buttonCell" }))),
                React.createElement("tbody", { className: "price-feed__body" }, filteredInstruments.slice(0, 5).map((item, i) => (React.createElement("tr", { key: `price-feed__row-${i}`, className: `price-feed__row price-feed__row--${i}` },
                    React.createElement("td", { className: "price-feed__cellContainer price-feed__cellContainer--instrument" },
                        React.createElement(Img, { className: "div price-feed__instrumentIcon", src: instrumentIcons.filter((el) => el.title === item.code)[0]?.imageUrl ?? "", alt: instrumentIcons.filter((el) => el.title === item.code)[0]?.imageAlt ?? "" }),
                        React.createElement("a", { href: `/${culture}/${instrumentItems[item.code.toLowerCase()] !== undefined ? instrumentItems[item.code.toLowerCase()] : "contract-specifications"}` }, item.code)),
                    React.createElement("td", { className: `price-feed__cellContainer ${item.askStyle}` }, item.ask),
                    React.createElement("td", { className: "price-feed__cellContainer" }, item.spread),
                    React.createElement("td", { className: `price-feed__cellContainer price-feed__cellContainer--with-icon price-feed__cellTitle ${item.dailyChangeStyle}` },
                        item.dailyChange,
                        item.dailyChangeIcon),
                    hasButtons && React.createElement("td", { className: "price-feed__cellContainer price-feed__buttonCell" },
                        React.createElement(ButtonV2, { design: "secondary-medium-wbg", href: buttonLinkUrl, openInNewTab: buttonOpenInNewTab }, buttonLinkText)))))))),
        hasButtons && React.createElement("div", { className: "price-feed__mobile-button-wrapper" },
            React.createElement(ButtonV2, { design: "secondary-medium-wbg", href: buttonLinkUrl, openInNewTab: buttonOpenInNewTab }, buttonLinkText)),
        footerText && React.createElement("div", { className: "price-feed__footerText" }, footerText)));
};
